body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

.section {
  padding: 4rem 2rem;
  text-align: center;
}

/* Navbar Responsiveness */
.navbar-menu {
  display: flex;
  flex-wrap: wrap;
}

.section-title {
  font-size: 2rem;
  color: #ff914d;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 60px;
    right: 20px;
    background: #121212;
    padding: 1rem;
    border-radius: 8px;
  }

  .navbar-menu.active {
    display: flex;
  }

  .language-selector {
    text-align: center;
  }
}

.navbar-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

