.hero-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(45deg, #ff914d, #1e1e2f, #6a1b9a);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  color: white;
}

.hero-content {
  max-width: 800px;
  padding: 2rem;
  text-align: center; /* Asegura el centrado */
  background: none; /* Elimina cualquier fondo adicional */
}


.hero-logo {
  width: 250px;
  height: auto;
  margin-bottom: 1rem;
  animation: fadeInScale 1.5s ease-out;
  /* Eliminamos el fondo radial y sombras que puedan parecer un rectángulo */
  box-shadow: none;
  background: none;
}

.hero-logo:hover {
  /* Dejamos solo el efecto hover sin fondo adicional */
  transform: scale(1.1);
  transition: all 0.3s ease;
}


.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #eeeeee;
}

.hero-button {
  background-color: #ff914d;
  color: white;
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #ff772d;
  transform: scale(1.05);
}

/* Animaciones */
@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .hero-logo {
    width: 180px;
  }
}