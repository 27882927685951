/* Global section styles */
.section {
  padding: 4rem 2rem;
  background-color: #121212;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title styles */
.section-title {
  color: #ff914d;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  /* text-transform: uppercase; */
}

/* Category styles */
.tech-category {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-title {
  color: #ff914d;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  /* text-transform: uppercase; */
}

/* Grid for each category */
.tech-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* Ajuste para tener 10 columnas */
  gap: 1.5rem; /* Espaciado entre los contenedores */
  justify-content: center; /* Centrar los ítems en caso de que haya menos de 10 */
  align-items: center; /* Alinear verticalmente los ítems */
}

/* Individual card styles */
.tech-card {
  background: #1e1e2f;
  color: white;
  width: 100px; /* Fixed width */
  height: 100px; /* Fixed height */
  border-radius: 50%; /* Circular shape */
  display: flex;
  flex-direction: column; /* Stack icon and text */
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensures no overflow outside the circular container */
}

.tech-card:hover {
  transform: scale(1.1); /* Slight hover effect */
  box-shadow: 0 8px 16px rgba(255, 145, 77, 0.5);
}

/* Icon styles */
.tech-icon {
  font-size: 1.8rem; /* Adjust icon size */
  margin-bottom: 0.5rem; /* Space between icon and text */
  color: #ff914d;
}

/* Name styles */
.tech-name {
  font-size: 0.8rem; /* Smaller text size */
  font-weight: bold;
  text-align: center;
  word-wrap: break-word; /* Break long words to fit within container */
  overflow: hidden; /* Truncate overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for truncated text */
  max-width: 80%; /* Ensure text fits within the container */
}

/* Responsive design */
@media (max-width: 768px) {
  .tech-grid {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Smaller containers on mobile */
    gap: 1rem; /* Adjust spacing for smaller screens */
  }

  .tech-card {
    width: 80px;
    height: 80px;
  }

  .tech-icon {
    font-size: 1.5rem;
  }

  .tech-name {
    font-size: 0.7rem;
  }
}
