.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #121212, #1c1c1c);
  color: white;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 1200px;
  gap: 3rem;
}

.about-content {
  flex: 1;
  min-width: 300px;
  text-align: left;
}

.section-title {
  font-size: 2.5rem;
  color: #ff914d;
  margin-bottom: 1rem;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.about-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about-list li {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 0.8rem 0;
}

.list-icon {
  color: #ff914d;
  font-size: 1.5rem;
  font-weight: bold;
}

.list-text {
  font-size: 1rem;
  color: #cccccc;
  line-height: 1.5;
}

.about-image-container {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}
