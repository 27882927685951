.services-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;
}

.services-main,
.services-complementary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.services-complementary {
  grid-template-columns: repeat(4, 1fr); /* 4 columnas en pantallas grandes */
}

@media (max-width: 768px) {
  .services-complementary {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */
  }
}

@media (max-width: 480px) {
  .services-complementary {
    grid-template-columns: 1fr; /* 1 columna en pantallas pequeñas */
  }
}

.service-card {
  background: #2a2a2a;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.8);
}

.service-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ff914d;
}

.service-title {
  font-size: 1.25rem;
  color: #ff914d;
  margin-bottom: 0.5rem;
}

.service-description {
  font-size: 1rem;
  color: #cccccc;
  line-height: 1.5;
}
