/* General section styles */
.contact-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1e1e2f, #121212);
  color: white;
  text-align: center;
}

/* Title styles */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #ff914d;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Contact container */
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Contact info items */
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-icon {
  font-size: 1.5rem;
  color: #ff914d;
}

.contact-link {
  color: #ff914d;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: white;
}

.contact-text {
  font-size: 1.2rem;
  color: #cccccc;
}

/* Buttons */
.contact-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #ff914d;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background: #ffffff;
  color: #ff914d;
  transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-container {
    padding: 1.5rem;
  }

  .contact-info {
    gap: 1rem;
  }

  .contact-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .button {
    width: 100%;
  }
}
