/* Sección general */
.section {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #121212; /* Fondo oscuro */
}

/* Título de la sección */
.section-title {
  color: #ff914d; /* Color de acento */
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Contenedor de la cuadrícula */
.clients-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 6 columnas por fila */
  gap: 1.5rem; /* Espaciado entre tarjetas */
  justify-items: center; /* Centrado horizontal */
}

/* Tarjetas individuales */
.client-card {
  width: 150px; /* Ancho fijo */
  height: 150px; /* Alto fijo */
  background: #1e1e2f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-card:hover {
  transform: scale(1.05); /* Escala al pasar el ratón */
  box-shadow: 0 8px 16px rgba(255, 145, 77, 0.5);
}

/* Ícono de cliente */
.client-icon {
  font-size: 2.5rem; /* Tamaño del ícono */
  color: #ff914d; /* Color de acento */
  margin-bottom: 0.5rem; /* Espacio entre ícono y texto */
}

/* Nombre del cliente */
.client-name {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

/* Diseño responsivo */
@media (max-width: 1024px) {
  .clients-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columnas por fila en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .clients-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas por fila en móviles */
  }
}
