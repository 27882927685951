.navbar {
  background: #121212;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-logo {
  height: 40px;
  width: auto;
}

.company-name {
  color: #ff914d;
  font-size: 1.5rem;
  font-weight: bold;
}

.company-name:hover {
  color: white;
}

.navbar-menu {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  list-style: none;
  margin: 0;
}

.navbar-menu li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.navbar-menu li a:hover,
.navbar-menu li a.active {
  color: #ff914d;
  font-weight: bold;
  border-bottom: 2px solid #ff914d;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

/* Estilo del selector de idioma */
.language-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.language-selector .fi {
  font-size: 16px; /* Tamaño reducido para las banderas */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.language-selector .fi:hover {
  transform: scale(1.3); /* Ligero aumento al pasar el cursor */
  opacity: 0.8;
}

.active-flag {
  transform: scale(1.2); /* Destaca la bandera activa */
  opacity: 1;
}

/* Adaptación para pantallas pequeñas */
@media (max-width: 768px) {
  .navbar-menu {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .language-selector .fi {
    font-size: 14px; /* Tamaño más pequeño en pantallas pequeñas */
  }
}
