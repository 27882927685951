/* Contenedor principal de la sección */
.team-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Dos tarjetas por fila */
  gap: 2rem; /* Espacio entre las tarjetas */
  justify-content: center;
  padding: 2rem 0;
}

/* Tarjetas individuales */
.team-card {
  text-align: center;
  background: #1e1e2f;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  height: 350px; /* Altura fija para todas las tarjetas */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Distribuir el contenido */
}

/* Hover para destacar tarjetas */
.team-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 145, 77, 0.5);
}

/* Estilo del avatar */
.team-card .MuiAvatar-root {
  width: 120px;
  height: 120px;
  font-size: 2rem; /* Tamaño del texto en el avatar */
  margin-bottom: 1rem;
  background-color: #ff914d;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Asegura que sea redondo */
}

/* Nombre del miembro */
.team-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

/* Rol del miembro */
.team-role {
  font-size: 1.2rem;
  color: #cccccc;
}

/* Ajuste responsivo para móviles */
@media (max-width: 768px) {
  .team-container {
    grid-template-columns: 1fr; /* Una tarjeta por fila */
    gap: 1.5rem;
  }

  .team-card {
    max-width: 100%;
    height: auto; /* Ajuste automático de altura */
    padding: 1rem;
  }

  .team-card .MuiAvatar-root {
    width: 100px;
    height: 100px;
    font-size: 1.5rem;
  }

  .team-name {
    font-size: 1.2rem;
  }

  .team-role {
    font-size: 1rem;
  }
}
